<template>
  <a-spin :spinning="spinning">
    <div style="border-right: 1px solid rgba(171, 166, 166, 0.65)">
      <div class="page-header-index-wide" style="overflow: auto;">
        <a-tree :treeData="treeData" :showLine="true" v-if="treeData.length" :defaultExpandedKeys="['0-0']" @select="selectEquipmentTypeTree" :defaultSelectedKeys="['0-0']">
          <template slot="custom" slot-scope="item">
            <a-row>
              <span @mouseover="test(item)" @mouseleave="test1">
                   <span>{{item.title}}</span>
                  <span v-if="item.key === selectEquipmentTypeKey || item.key === mouseoverEquipmentKey" style="width:25%;textAlign:'center'">
                     <span class="button" style="margin-left: 10px;" v-auth="auth_equipment2.TYPE_CREATE_AND_UPDATE" @click="()=> customHandleAdd(item.key)"><a-icon type="plus-circle"/></span>
                     <span class="button" style="margin-left: 5px;" v-if="item.key" v-auth="auth_equipment2.TYPE_CREATE_AND_UPDATE" @click="()=> handleEdit(item.key)"> <a-icon type="edit"/></span>
                     <span class="button" style="margin-left: 5px;" v-if="item.key" v-auth="auth_equipment2.TYPE_DELETE" @click="(e)=> handleDelete(item.key)"><a-icon type="minus-circle"/></span>
                  </span>
              </span>
            </a-row>
          </template>
        </a-tree>
      </div>
      <a-modal
        title="设备类型增加"
        @ok="handleAddOk"
        @cancel="closeAddModal"
        :visible="addModal"
        type="add">
        <typeform ref="addRef" :treeData="treeData" :sup_this="sup_this" @addSuccess="addSuccess"
                  @addError="addError"></typeform>
      </a-modal>
      <a-modal
        title="设备类型编辑"
        @ok="handleEditOk"
        @cancel="closeEditModal"
        :visible="editModal"
        type="edit">
        <typeform ref="editRef" :treeData="treeData" :sup_this="sup_this" @editSuccess="editSuccess"
                  @editError="editError"></typeform>
      </a-modal>
    </div>
  </a-spin>
</template>
<script>
  import entityCRUD from '@/views/common/mixins/entityCRUD'
  import SERVICE_URLS from '@/api/service.url'
  import typeform from './typeForm'

  export default {
    name: 'equipmentTypeTree',
    mixins: [entityCRUD],
    components: { typeform },
    props: {
      sup_this: {
        type: Object,
        required: true
      },
      type: {
        type: String,
      }
    },
    data () {
      return {
        addModal: false,
        editModal: false,
        selectEquipmentTypeKey: '0-0',
        entityBaseUrl: SERVICE_URLS.equipment2.equipmentTypeApi,
        treeData: [],
        initColumns: [],
        mouseoverEquipmentKey: '',
        spinning: true
      }
    },
    computed: {},
    created () {
      this.search()
    },
    methods: {
      customHandleAdd (parentId) {
        this.addModal = true
        this.$nextTick(function () {
          if (parentId) {
            this.$refs.addRef.form.setFieldsValue({
              parentId: parentId.toString()
            })
          } else {
            this.$refs.addRef.form.setFieldsValue({
              parentId: parentId
            })
          }
        })
      },
      search () {
        this.spinning = true
        this.$http(this, {
          url: SERVICE_URLS.equipment2.equipmentTypeApi.search,
          noTips: true,
          data: { type: this.type },
          success: (data) => {
            this.treeData = []
            this.treeData.push(data.body)
            this.spinning = false
          }
        })
      },
      selectEquipmentTypeTree (selectedKeys) {
        this.selectEquipmentTypeKey = selectedKeys[0]
        if (selectedKeys[0] === '0-0') {
          this.sup_this.categoryId = ''
        } else {
          this.sup_this.categoryId = selectedKeys[0]
        }
      },
      addSuccess (data) {
        this.search()
        this.addModal = false
      },
      editSuccess () {
        this.search()
        this.editModal = false
      },
      test (item) {
        this.mouseoverEquipmentKey = item.key
      },
      test1 () {
        this.mouseoverEquipmentKey = ''
      },
      deleteSucceed () {
        this.search()
        this.$emit('deleteSucceed')
      }
    }
  }
</script>
<style scoped>
  .ant-tree-title {
    width: 100%;
  }

  .title {
    float: left;
  }
</style>