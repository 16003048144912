<template>
    <a-row :gutter="16">
      <a-col :sm="24" :md="12" :lg="8" :xl="4">
        <a-card>
          <a-statistic title="当前部门" :value="deptName"  :valueStyle="{fontSize:'22px'}"  style="text-align: center">
            <template slot="formatter">
              <a-popover placement="bottom">
                <template slot="content">
                  <a-radio-group @change="onDataTypeChange" v-model="dataType" >
                    <a-radio value="orgAll">显示该部门及部门以下数据</a-radio>
                    <br>
                    <a-radio value="orgSelf">只显示当前部门数据</a-radio>
                  </a-radio-group>
                  <div>
                    <a-tree
                      :treeData="organizationTree"
                      @select="selectDept"
                    ></a-tree>
                  </div>
                </template>
                <a>{{deptName}}<a-icon type="down" style="font-size: 14px; margin-left: 5px;" /></a>
              </a-popover>
            </template>
            <template slot="suffix">

            </template>
          </a-statistic>
        </a-card>
      </a-col>
      <a-col :sm="24" :md="12" :lg="8" :xl="4">
        <a-card>
          <a-statistic title="设备总数量" :value="statisticsList.totalNumber"   style="text-align: center">

          </a-statistic>
        </a-card>
      </a-col>
      <a-col :sm="24" :md="12" :lg="8" :xl="4">
        <a-card>
          <a-statistic title="在线设备数量" :value="statisticsList.onlineNumber"   style="text-align: center">

          </a-statistic>
        </a-card>
      </a-col>
      <a-col :sm="24" :md="12" :lg="8" :xl="4">
        <a-card>
          <a-statistic title="库存设备数量" :value="statisticsList.stockNumber"  style="text-align: center">

          </a-statistic>
        </a-card>
      </a-col>
      <a-col :sm="24" :md="12" :lg="8" :xl="4">
        <a-card>
          <a-statistic title="其中正常设备" :value="statisticsList.normalNumber"   style="text-align: center">

          </a-statistic>
        </a-card>
      </a-col>
      <a-col :sm="24" :md="12" :lg="8" :xl="4">
        <a-card>
          <a-statistic title="其中损坏设备" :value="statisticsList.damageNumber"  style="text-align: center">

          </a-statistic>
        </a-card>
      </a-col>
    </a-row>
</template>
<script>
  import SERVICE_URLS from '@/api/service.url'
  export default {
    name: 'statistics',
    components: {  },
    props: {
      treeData: {
        type: Array
      },
      sup_this: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        statisticsList: {},
        deptName: '',
        value: '',
        dataType: 'orgAll',
        deptKey: '',
        organizationTree: []
      }
    },
    computed: {
      //获取当前单位
      currentOrg () {
        return this.$store.getters.currentOrg
      },
      currentUser () {
        return this.$store.getters.currentUser
      },

    },
    created () {
       this.userDept()
       this.getOrganizationTree()
       this.loadStatistics()
    },
    methods: {
      loadStatistics() {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.equipmentInfoApi.statistics,
          noTips: true,
          data: {
            departmentId: this.deptKey,
            orgRadioType: this.dataType
          },
          success: (data) => {
            this.statisticsList = data.body
          }
        })
      },
      onSelect(selectedKeys, info) {
        // this.deptName = info.node.title
        this.substringDeptName(info.node.title)
      },
      userDept () {
        this.deptKey = this.currentDeptId().organizationId
        this.sup_this.departmentId = this.currentDeptId().organizationId
        // this.deptName =  this.currentDeptId().organizationName
        this.substringDeptName(this.currentDeptId().organizationName)
      },
      onDataTypeChange() {
        this.sup_this.orgRadioType = this.dataType
        this.loadStatistics()
      },
      getOrganizationTree() {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.organizationTree = data.body
          }
        })
      },
      selectDept(selectedKeys, info) {
        // this.deptName = info.node.dataRef.title
        this.substringDeptName(info.node.dataRef.title)
        this.deptKey = info.node.dataRef.value
        this.sup_this.departmentId = info.node.dataRef.value
        this.loadStatistics()
      },
      substringDeptName(name) {
        if (name.length > 6) {
          this.deptName = name.substring(0, 6) + '...'
        } else {
          this.deptName = name
        }
      }
    }
  }
</script>
<style scoped>
  .headerInfo{
    background: white;
    padding: 20px;
    text-align: center;
  }
  .deptTree{
    position: absolute;
    z-index: 1000;
    padding: 10px;
    background: white;
    width: 281px;
  }
</style>