export const repertoryColumns = () => {
  return [
    {
      title: '序号',
      width: 50,
      fixed: 'left',
      scopedSlots: { customRender: '_index' },
      align: 'center',
    },
    {
      title: '设备名称',
      ellipsis: true,
      dataIndex: 'name',
      key: 'name',
      width: 200,
      scopedSlots: { customRender: 'name' },
      align: 'center',
    },
    {
      title: '设备编号',
      dataIndex: 'no',
      ellipsis: true,
      width: 200,
      key: 'no',
      scopedSlots: { customRender: 'no' },
      align: 'center',
    },
    {
      title: '设备型号',
      dataIndex: 'model',
      ellipsis: true,
      key: 'model',
      width: 150,
      align: 'center',
      scopedSlots: { customRender: 'model' },
      // customRender: (text, record) => {
      //   const time = `${record.model ? record.model : '-'}` //es6写法
      //   return time
      // },
    },
    {
      title: '设备所属部门',
      dataIndex: 'department.name',
      ellipsis: true,
      width: 200,
      align: 'center',
    },
    {
      title: '设备状态',
      dataIndex: 'deviceCondition',
      width: 100,
      scopedSlots: { customRender: 'deviceCondition' },
      align: 'center',
    },
    {
      title: '操作类型',
      width: 100,
      dataIndex: 'transferType',
      scopedSlots: { customRender: 'transferType' },
      align: 'center',
    },
    {
      title: '入库类型',
      dataIndex: 'type',
      width: 150,
      scopedSlots: { customRender: 'type' },
      align: 'center',
    },
    {
      title: '存放仓库',
      dataIndex: 'depot.name',
      width: 200,
      scopedSlots: { customRender: 'depot.name' },
      align: 'center',
    },
    {
      title: '操作',
      key: 'action',
      width: 300,
      fixed: 'right',
      scopedSlots: { customRender: 'action' },
      align: 'center',
    },
  ]
}
export const deviceCondition = () => {
  return [
    { value: 'WELL', name: '良好' },
    { value: 'DAMAGE', name: '损坏' },
  ]
}
export const transferType = () => {
  return [
    { value: 'RK', name: '入库' },
    { value: 'CK', name: '出库' },
    { value: 'ZY', name: '转移' },
    { value: 'DB', name: '调拨' },
  ]
}
export const type = () => {
  return [
    { value: 'ADD', name: '新增入库' },
    { value: 'ONLINE', name: '在线设备入库' },
  ]
}
